import client from '@/api_client/client';
import { EP_TENANT } from '@/constants/endpoints';
import appConfigService from '@/services/app-config/app-config.service';
import { BASE_APP_CONFIG } from '@/services/app-config/base_app_config';
import { IGetPublicTenantResponse, IRawTenantResponse, ITenant } from '@/types/apiClient/ITenant';

export function get() {
	return client
		.get<IRawTenantResponse>(EP_TENANT)
		.then((res) => res.data)
		.then((raw) => {
			return {
				...raw,
				webAppConfig: appConfigService.parseFromServerJSON(raw.webAppConfig),
			};
		});
}

export function update(value: Partial<ITenant>) {
	return client.patch<IRawTenantResponse>(EP_TENANT, {
		...value,
	});
}

export function create(args: { id: string; name: string | null }) {
	return client.post<IRawTenantResponse>(EP_TENANT, args);
}

function getPublicInfo(): Promise<IGetPublicTenantResponse> {
	return client
		.get<unknown>('public/tenant')
		.then((res) => res.data)
		.then((raw) => {
			if (typeof raw !== 'object' || raw === null) {
				return {
					name: 'TimeTime.in',
					domain: 'www.timetime.in',
					webAppConfig: {
						...BASE_APP_CONFIG,
					},
				};
			}
			return {
				name: 'name' in raw ? String(raw.name) : 'TimeTime.in',
				domain: 'domain' in raw ? String(raw.domain) : 'www.timetime.in',
				webAppConfig: 'webAppConfig' in raw ? appConfigService.parseFromServerJSON(raw.webAppConfig) : BASE_APP_CONFIG,
			};
		});
}

export default { getPublicInfo, get, update, create };
